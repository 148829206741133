import React from 'react';
import { Box } from '@chakra-ui/react';
import { Element } from 'react-scroll';
import NFTCarousel from './sections/NFTCarousel';
import Game from './sections/Game';
import Economy from './sections/Economy';
import Market from './sections/Market';
import Partners from './sections/Partners';
import Menu from './layout/Menu';
import Footer from './layout/Footer';
import AboutUs from './sections/AboutUs';
import Home from './sections/Home';

const SECTIONS = [
    { name: "home", component: <Home /> },
    { name: "nft", component: <NFTCarousel /> },
    { name: "game", component: <Game /> },
    { name: "economy", component: <Economy /> },
    { name: "market", component: <Market /> },
    { name: "about-us", component: <AboutUs /> },
    { name: "partners", component: <Partners /> },
];

const LandingPage = () => {
    return (
        <Box
            bgImage="url('/images/background_island.png')"
            bgSize="cover"
            bgPosition="center"
            bgRepeat="no-repeat"
            bgAttachment="fixed"
            color="white"
        >
            {/* Menu */}
            <Menu />

            {/* Content */}
            <Box pt="60px" id="top"> {/* Prevent overlap with fixed menu */}

                {/* Sections */}
                {SECTIONS.map((section) => (
                    <Element key={section.name} name={section.name}>
                        {section.component}
                    </Element>
                ))}

                {/* Footer */}
                <Footer />
            </Box>
        </Box>
    );
};

export default LandingPage;