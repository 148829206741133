import React from 'react';
import {
    Flex,
    Image,
    VStack,
    HStack,
    Spacer,
    Drawer,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    DrawerHeader,
    DrawerBody,
    Text,
    Stack,
    useDisclosure,
    Link,
    Box,
} from '@chakra-ui/react';
import { FaBookOpen, FaDiscord, FaTwitter } from 'react-icons/fa';
import { Link as ScrollLink } from 'react-scroll';
import { ToggleButton } from '../components/ToggleButton';

const MENU_ITEMS = [
    { to: 'top', label: 'Home' },
    { to: 'game', label: 'Game' },
    { to: 'economy', label: 'Economy' },
    { to: 'market', label: 'Market' },
    { to: 'about-us', label: 'About Us' },
];

const SOCIAL_LINKS = [
    { href: 'https://twitter.com/ForLootAndGlory', icon: <FaTwitter size={18} />, label: 'Twitter' },
    { href: 'https://discord/', icon: <FaDiscord size={18} />, label: 'Discord' },
    { href: 'https://docs.forlootandglory.io', icon: <FaBookOpen size={18} />, label: 'Docs' },
];

const MenuLink = ({ to, label, onClick }) => (
    <Link as={ScrollLink} to={to} smooth offset={-60} duration={500} onClick={onClick}>
        <Text fontWeight="semibold" fontSize={15} color="white">
            {label}
        </Text>
    </Link>
);

const SocialIcon = ({ href, icon, label }) => (
    <Link href={href} target="_blank" rel="noopener noreferrer" aria-label={label} >
        <Box pb={1} color={'white'}>
            {icon}
        </Box>
    </Link>
);

const Menu = () => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {/* Navbar */}
            <Flex
                justify="space-between"
                align="center"
                px={{ base: 3, md: 6 }}
                py={4}
                bg="rgba(0, 0, 0, 0.9)"
                position="fixed"
                top="0"
                left="0"
                right="0"
                zIndex="1000"
                borderBottom="1px solid rgba(255, 255, 255, 0.2)"
                h="60px"
            >
                <Flex width="100%">
                    <ScrollLink to="top" smooth duration={500}>
                        <Image src="images/logo/flag_logo_round.png" alt="Game Logo" boxSize={{ base: '40px', md: '45px' }} cursor="pointer" />
                    </ScrollLink>
                    <Spacer />

                    {/* Mobile Toggle */}
                    <ToggleButton display={{ base: 'block', md: 'none' }} isOpen={isOpen} aria-label="Open Menu" onClick={onOpen} />

                    {/* Desktop Menu */}
                    <Flex display={{ base: 'none', md: 'flex' }} gap={10}>
                        <HStack spacing={{ base: 4, md: 8 }}>
                            {MENU_ITEMS.map((item) => (
                                <MenuLink key={item.to} {...item} />
                            ))}
                        </HStack>
                        <HStack spacing={5}>
                            {SOCIAL_LINKS.map((link, index) => (
                                <SocialIcon key={index} {...link} />
                            ))}
                        </HStack>
                    </Flex>
                </Flex>
            </Flex>

            {/* Drawer (Mobile) */}
            <Drawer isOpen={isOpen} onClose={onClose} placement="left">
                <DrawerOverlay>
                    <DrawerContent bg="rgba(0, 0, 0, 0.9)">
                        <DrawerCloseButton />
                        <DrawerHeader>
                            <ScrollLink to="top" smooth duration={500}>
                                <Image src="images/logo/flag_logo_round.png" alt="Game Logo" boxSize="50px" mt={-2} />
                            </ScrollLink>
                        </DrawerHeader>
                        <DrawerBody>
                            <Stack minH="full">
                                <VStack align="start" spacing={6} ml={2}>
                                    {MENU_ITEMS.map((item) => (
                                        <MenuLink key={item.to} {...item} onClick={onClose} />
                                    ))}
                                </VStack>
                                <Spacer />
                                <Flex alignItems="center">
                                    <Text fontSize="sm">© FLAG {new Date().getFullYear()}</Text>
                                    <Spacer />
                                    <HStack spacing={5}>
                                        {SOCIAL_LINKS.map((link, index) => (
                                            <SocialIcon key={index} {...link} />
                                        ))}
                                    </HStack>
                                </Flex>
                            </Stack>
                        </DrawerBody>
                    </DrawerContent>
                </DrawerOverlay>
            </Drawer>
        </>
    );
};

export default Menu;
