
import { Image, VStack, Text } from '@chakra-ui/react';

const Home = () => {
    return (
        <VStack
            textAlign="center"
            spacing={{ base: 10, md: 20 }}
            bg="rgba(0, 0, 0, 0.6)"
            px={{ base: 4, md: 8 }}
            pb={130}
        >
            <Image src="images/logo/flag_logo.png" alt="FLAG Logo" mt={150} width={{ base: "800px", md: "1400px" }} />
            <Text fontSize={{ base: "md", md: "xl" }}>
                As a true pirate, join us to <b>Drink, Sail, Fight and F*#$!</b> Decentralized, with massive burn, and 100% on-chain adventures! <br />Powered by <b>Skale Network</b>!
            </Text>
        </VStack>
    );
};

export default Home;
