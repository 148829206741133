import { Button, Flex, HStack, Link } from "@chakra-ui/react";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import NFTSection from "../components/Section";
import { TbRadar } from "react-icons/tb";
import HighlightedText from "../components/HighlightedText";

const AboutUs = () => {

    return (
        <NFTSection title={'About Us'}>
            <Flex flexDirection={'column'} maxW="1200px" gap={10}>
                <HighlightedText fontSize={{ base: "md", md: "xl" }} mx={5}>
                    {"We are a {passionate team} driven by our {love} for {gaming, crypto, and decentralization}, and we’re excited to share our vision with you—{embark on this journey} with us!"}
                </HighlightedText>
                <HStack justify="center" spacing={4}>
                    <Link href="https://discord.com/invite/Jqvnb7E8gc" isExternal >
                        <Button leftIcon={<FaDiscord />} colorScheme="twitter" color={'white'}>
                            Discord
                        </Button>
                    </Link>
                    <Link href="https://twitter.com/ForLootAndGlory" isExternal>
                        <Button leftIcon={<FaTwitter />} colorScheme="twitter" color={'white'}>
                            Twitter
                        </Button>
                    </Link>
                    <Link href="https://dappradar.com/dapp/forlootandglory" isExternal>
                        <Button leftIcon={<TbRadar />} colorScheme="twitter" color={'white'}>
                            DappRadar
                        </Button>
                    </Link>
                </HStack>
            </Flex>
        </NFTSection >
    );
};

export default AboutUs;