import React from 'react';
import { Box, Flex, Image, SimpleGrid, Text, ListItem, List } from '@chakra-ui/react';
import NFTSection from '../components/Section';
import HighlightedText from '../components/HighlightedText';

const Market = () => {

    const cards = [
        {
            icon: 'images/token/barrel.png',
            title: "INVESTMENT TRENDS", //\nIN WEB3 GAMES
            bullet: 'images/token/coin_copper.png',
            description: [
                "According to DAppRadar, {Web3 games} attracted {2.4 billion dollars} in {investments}, a {38% increase} from the previous year. By the end of the year, the sector had grown to {2,916 games}.",
                "However, the industry also faced significant challenges, with {450 games} failing due to {economic difficulties} and {security breaches}, such as {hacks}.",
                "These issues highlight the {risks} and {volatility} of the {Web3 gaming market}, underscoring the need for stronger {economic stability} and {security measures}."
            ],
        },
        {
            icon: 'images/token/bottle.png',
            title: "DAILY ACTIVE USERS",
            bullet: 'images/token/coin_silver.png',
            description: [
                "By the end of {2023}, the {Web3 gaming sector} experienced substantial growth, with the total number of {players surpassing 37 million}.",
                "This surge in players was accompanied by a remarkable increase in {user engagement}, as the number of {daily active wallets} nearly doubled, rising from {775,000} to {1.492 million}.",
                "These figures reflect the growing {popularity} and {adoption} of {Web3 games}, indicating a strong shift towards {blockchain-based gaming} and a more engaged {player base}."
            ],
        },
    ];

    return (
        <NFTSection title={'Market'} bgOpacity='0.7'>
            <Flex flexDirection={'column'} maxW="1000px" gap={10}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
                    {cards.map((card, index) => (
                        <Box
                            key={index}
                            borderRadius="md"
                            shadow="md"
                            p={6}
                            bg="rgba(0, 0, 0, 0.7)"
                            _hover={{ shadow: "lg", transform: "translateY(-5px)", transition: "0.3s ease" }}
                        >
                            <Flex justifyContent={'center'} alignItems={'center'} mb={6} gap={3} pr={'25px'}>
                                <Image src={card.icon} boxSize={'35px'} mt={'0px'} />
                                <Text fontWeight={'bold'} whiteSpace={'pre-line'}>{card.title}</Text>
                            </Flex>
                            <List spacing={2} textAlign="left">
                                {card.description.map((line, idx) => (
                                    <ListItem key={idx} display="flex" alignItems="flex-start">
                                        <HighlightedText textAlign={'justify'} whiteSpace={'pre-line'} fontSize={13}>{line}</HighlightedText>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))}
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 2 }} justifyContent="center">
                    <Text
                        fontSize="20"
                        fontWeight="medium"
                        fontStyle="italic"
                        lineHeight="9"
                        mt={12}
                    >
                        <Image src="/images/misc/quote_left.png" display="inline" alt="Left Quote" boxSize="1.2em" mr={3} />
                        With our <Text as="span" fontWeight="bold" color="yellow.400"> innovative economic model and approach</Text>,
                        For Loot and Glory can significantly differentiate itself from current market offerings, as we address the issues encountered by other projects.
                        <Image src="/images/misc/quote_right.png" display="inline" alt="Right Quote" boxSize="1.25em" ml={3} />
                    </Text>
                    <Flex justifyContent="center">
                        <Image src='images/tickets.png'></Image>
                    </Flex>
                </SimpleGrid>

            </Flex>
        </NFTSection>
    );
};

export default Market;
