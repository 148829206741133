import { Box, VStack } from '@chakra-ui/react';
import Title from './Title';

const NFTSection = ({ title, bgOpacity = "0.6", children, ...props }) => {
    return (
        <Box pt={["20px", "40px"]} pb={"70px"} bg={"rgba(0, 0, 0, " + bgOpacity + ")"} {...props}>
            <VStack textAlign="center" spacing={8}>
                <Title>{title}</Title>
                <Box>{children}</Box>
            </VStack>
        </Box>
    );
};

export default NFTSection;