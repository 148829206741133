import { baseTheme, extendTheme, } from '@chakra-ui/react';
import { theme as proTheme } from '@chakra-ui/pro-theme'
import LandingPage from './LandingPage';

export const flagTheme = extendTheme(
  {
    colors: { ...baseTheme.colors, brand: baseTheme.colors.yellow },

    // components: {
    //   Link: {
    //     baseStyle: {
    //       textDecoration: 'underline',
    //       textDecorationColor: 'red.500',
    //       _hover: {
    //         textDecorationColor: 'red.500',
    //       },
    //     },
    //   },
    // },
  },
  proTheme,
)

function App() {
  return (
    <LandingPage />
  );
}

export default App;
