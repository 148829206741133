import React from 'react';
import { Box, Flex, Image, SimpleGrid, Text, ListItem, List, VStack, Button, Link } from '@chakra-ui/react';
import NFTSection from '../components/Section';
import HighlightedText from '../components/HighlightedText';


const Economy = () => {

    const cards = [
        {
            icon: 'images/token/coin_copper.png',
            title: "TOKEN SUPPLY",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "$FLAG boasts a {limited supply of 1,000,000 tokens}, ensuring scarcity and value."
            ],
        },
        {
            icon: 'images/token/coin_silver.png',
            title: "STAKING REWARDS",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "20% of premium {building fees} and all {FLAG fees} from quest maps are {redistributed to stakers} over a {five-year period}, benefiting both holders and speculators.",
            ],
        },
        {
            icon: 'images/token/coin_golden.png',
            title: "GAMER INCENTIVES",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "{Staking $FLAG} tokens provides {significant discounts} on in-game actions, with 10,000 tokens staked offering an 80% discount, encouraging active participation.",
                "The $FLAG token’s {unique economic model} ensures value for all stakeholders, supporting the growth of a thriving {gaming ecosystem}"
            ],
        },
    ];

    const cards2 = [
        {
            icon: 'images/token/chest_common.png',
            title: "ASSET MINTING",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Proceeds: 91% to End-Game Chest, 9% to Team.",
            ],
        },
        {
            icon: 'images/token/chest_rare.png',
            title: "BUILDING FEES",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Premium Allocation: 90% to Building Owner, 10% to Royalty in $FLAG",
            ],
        },
        {
            icon: 'images/token/chest_legendary.png',
            title: "FEE STRUCTURE",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Sustainability: All actions have transaction fees.",
                "Allocation: 90% to End-Game Chest, 10% to Team."
            ],
        },
        {
            icon: 'images/token/compass.png',
            title: "ECONOMIC IMPACT",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Promotes continuous investment for rewards.",
                "Sustains player interest and economic viability.",
                "Accumulates significant value to maintain player interest."
            ],
        },
    ];


    return (
        <NFTSection title={'Economy'}>
            <Flex flexDirection={'column'} maxW="1200px" gap={10}>
                <Box
                    borderRadius="md"
                    p={6}
                    bg="rgba(0, 0, 0, 0.7)"
                >
                    <VStack spacing={10}>
                        <Flex justifyContent={'center'} alignItems={'center'} gap={5} pr={'30px'}>
                            <Text fontSize={20} fontWeight={'bold'} whiteSpace={'pre-line'}>PART 1 - INTRODUCING $FLAG TOKEN</Text>
                        </Flex>

                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
                            {cards.map((card, index) => (
                                <Box
                                    key={index}
                                    borderRadius="md"
                                    shadow="md"
                                    p={6}
                                    bg="rgba(0, 0, 0, 0.6)"
                                    _hover={{ shadow: "lg", transform: "translateY(-5px)", transition: "0.3s ease" }}
                                >
                                    <Flex justifyContent={'center'} alignItems={'center'} mb={6} gap={3} pr={'25px'}>
                                        <Image src={card.icon} boxSize={'35px'} mt={'0px'} />
                                        <Text fontWeight={'bold'} whiteSpace={'pre-line'}>{card.title}</Text>
                                    </Flex>
                                    <List spacing={2} textAlign="left">
                                        {card.description.map((line, idx) => (
                                            <ListItem key={idx} display="flex" alignItems="flex-start">
                                                <Image src={card.bullet} boxSize={5} mt={0} mr={2} />
                                                <HighlightedText textAlign={'justify'} fontSize={13}>{line}</HighlightedText>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            ))}
                        </SimpleGrid>

                        <Flex flexDirection={['column', 'row']} gap={3}>
                            <Link
                                href="https://docs.forlootandglory.io"
                                target="_blank"
                                rel="noopener noreferrer"
                                _hover={{ textDecoration: 'none' }}
                            >
                                <Button
                                    colorScheme="teal"
                                    width="220px"
                                >
                                    <Text fontSize={16}>Bridge Polygon ⇔ Skale</Text>
                                </Button>
                            </Link>
                            <Link
                                href="https://www.sushi.com/skale-europa/swap?token0=0xE0595a049d02b7674572b0d59cd4880Db60EDC50&token1=0xcdF030a3E65f917DFa8d74555A64a5eC5303c88e"
                                target="_blank"
                                rel="noopener noreferrer"
                                _hover={{ textDecoration: 'none' }}
                            >
                                <Button
                                    colorScheme="teal"
                                    width="220px"
                                >
                                    <Text fontSize={16}>Buy FLAG on Sushi</Text>
                                </Button>
                            </Link>
                            <Link
                                href="https://app.ichi.org/vault/token/0xcdf030a3e65f917dfa8d74555a64a5ec5303c88e/"
                                target="_blank"
                                rel="noopener noreferrer"
                                _hover={{ textDecoration: 'none' }}
                            >
                                <Button
                                    colorScheme="teal"
                                    width="220px"
                                >
                                    <Text fontSize={16}>FLAG Vault on ICHI</Text>
                                </Button>
                            </Link>

                            <Link
                                href="https://app.ichi.org/vault/token/0xe0595a049d02b7674572b0d59cd4880db60edc50/"
                                target="_blank"
                                rel="noopener noreferrer"
                                _hover={{ textDecoration: 'none' }}
                            >
                                <Button
                                    colorScheme="teal"
                                    width="220px"
                                >
                                    <Text fontSize={16}>SKL Vault on ICHI</Text>
                                </Button>
                            </Link>
                        </Flex>

                    </VStack>
                </Box>

                <Box
                    borderRadius="md"
                    shadow="md"
                    p={6}
                    bg="rgba(0, 0, 0, 0.7)"
                >
                    <VStack spacing={10}>
                        <Flex justifyContent={'center'} alignItems={'center'} gap={5} pr={'30px'}>
                            <Text fontSize={20} fontWeight={'bold'} whiteSpace={'pre-line'}>PART 2 - GAME FINANCIAL MECHANICS</Text>
                        </Flex>
                        <HighlightedText px={10} mx={5}>
                            {"The game's economy operates on {strategic revenue allocation}, where {profits from asset creation} contribute to the {final reward pool}, and {usage fees} drive its growth.\nPlayers are incentivized by {attractive rewards} and a {self-sustaining economic cycle}, ensuring {long-term engagement} and {stability}.\nBuilding owners gain from {premium fees}, with a portion directed to the game's protocol in { $FLAG tokens}, strengthening {infrastructure}.\nOffered in {limited supply}, buildings provide owners the flexibility to use them {personally} or offer {premium services} for additional revenue."}
                        </HighlightedText>
                        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={6}>
                            {cards2.map((card, index) => (
                                <Box
                                    key={index}
                                    borderRadius="md"
                                    shadow="md"
                                    p={6}
                                    bg="rgba(0, 0, 0, 0.6)"
                                    // bgGradient="linear(to-br, yellow.400 10%, transparent)"
                                    _hover={{ shadow: "lg", transform: "translateY(-5px)", transition: "0.3s ease" }}
                                >
                                    <Flex justifyContent={'center'} alignItems={'center'} mb={6} gap={3} pr={'25px'}>
                                        <Image src={card.icon} boxSize={'35px'} mt={'0px'} />
                                        <Text fontWeight={'bold'} whiteSpace={'pre-line'}>{card.title}</Text>
                                    </Flex>
                                    <List spacing={2} textAlign="left">
                                        {card.description.map((line, idx) => (
                                            <ListItem key={idx} display="flex" alignItems="flex-start">
                                                {/* <ListIcon as={FaSkullCrossbones} color="yellow.400" mt={1} /> */}
                                                <Image src={card.bullet} boxSize={5} mt={0} mr={2} />
                                                <Text textAlign={'justify'} fontSize={13}>{line}</Text>
                                            </ListItem>
                                        ))}
                                    </List>
                                </Box>
                            ))}
                        </SimpleGrid>
                        <Flex flexDirection={['column', 'row']} gap={3}>
                            <Link
                                target="_blank"
                                rel="noopener noreferrer"
                                _hover={{ textDecoration: 'none' }}
                            >
                                <Button
                                    colorScheme="teal"
                                    width="220px"
                                >
                                    <Text fontSize={16}>Stake FLAG (coming soon)</Text>
                                </Button>
                            </Link>
                        </Flex>

                    </VStack>
                </Box>
            </Flex>
        </NFTSection >
    );
};

export default Economy;
