import React from 'react';
import { Flex, Image, VStack } from '@chakra-ui/react';
import { Link } from 'react-scroll';
import NFTSection from '../components/Section';

const Partners = () => {

    return (
        //title={'Partners & Technologies'}
        <NFTSection bgOpacity='0.8'>
            <VStack>
                <Flex flexDirection={['row', 'row']} my={5} gap={[5, 12]}>
                    <Link href="https://skale.space/" isExternal>
                        <Image src={'images/partners/Skale.png'} height={['25px', '60px']} objectFit="contain" />
                    </Link>
                    <Link href="https://app.ichi.org/" isExternal>
                        <Image src={'images/partners/Ichi.png'} height={['25px', '60px']} objectFit="contain" />
                    </Link>
                    <Link href="https://www.streamnft.tech/" isExternal>
                        <Image src={'images/partners/StreamNFT.png'} height={['25px', '60px']} objectFit="contain" />
                    </Link>

                </Flex>
                <Flex flexDirection={['row', 'row']} my={5} gap={[5, 12]}>
                    <Link href="https://ens.domains/" isExternal>
                        <Image src={'images/partners/Ens.png'} height={['20px', '40px']} objectFit="contain" />
                    </Link>
                    <Link href="https://arweave.org/" isExternal>
                        <Image src={'images/partners/Arweave.png'} height={['20px', '40px']} objectFit="contain" />
                    </Link>
                </Flex>
            </VStack>
        </NFTSection>

    );
};

export default Partners;
