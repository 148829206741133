import React from 'react';
import { Box, Flex, Image, VStack, SimpleGrid, Text, ListItem, List, Button, Link } from '@chakra-ui/react';
import NFTSection from '../components/Section';
import { FaBookOpen } from 'react-icons/fa';
import { GiSwordClash } from 'react-icons/gi';
import HighlightedText from '../components/HighlightedText';

const Game = () => {

    const cards = [
        {
            icon: 'images/token/map_common.png',
            title: "PVE MODE\nPIRATE'S LANDING",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Level up and craft powerful gear in the Forge.",
                "Upgrade your Tavern to produce $BARRELS efficiently.",
                "Unlock advanced levels and Gold Coins for end-game progress.",
                "Go on Treasure Hunts to collect Coins and EXP."
            ],
        },
        {
            icon: 'images/token/map_rare.png',
            title: "PVP MODE\nPIRATE'S RAMPAGE",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Build and upgrade a powerful fleet to rule the seas.",
                "Command strategic naval battles across vast seas.",
                "Embark on Odysseys with a skilled crew and powerful ship.",
                "Compete in missions, claim rewards, and manage resources.",
            ],
        },
        {
            icon: 'images/token/map_legendary.png',
            title: "END GAME\nTHE CROWN OF GOLD",
            bullet: 'images/token/FLAG_transparent.png',
            description: [
                "Obtain Legendary Odyssey Maps for epic treasure adventures.",
                "Uncover hidden treasures to gain riches and renown.",
                "Take on ultimate end-game challenges for legendary rewards.",
                "Seek and claim the ultimate treasure."
            ],
        },
    ];

    const innovations = [
        {
            title: 'DECENTRALIZATION',
            image: 'images/brothel_800x600.png',
            reverse: false,
            text: 'Players can enjoy an autonomous gaming experience where community interaction and strategic gameplay lead to potential earnings. Having everything on-chain, with the front-end (website) mirrored on Arweave, ensuring the game remains playable regardless of circumstances. ENS domain. We consider the project to be 100% decentralized once keys (smart contracts) are thrown away.',
            description: [
                "{No gas fees} for every actions, thank's Skale Network!",
                "Offer a {decentralized gaming experience}, a feature often overlooked by the majority of projects. The front-end (website) is mirrored on {Arweave}, ensuring the game remains playable regardless of circumstances. {ENS domain} for the domain itself.",
                "Steer clear of the pitfalls typically associated with Play-to-Earn (P2E) models by establishing a {sustainable economy} that doesn't rely on {continuous influx of new users} to remain viable, a feature uncommonly found in most, if not all, play-to-earn games.",
                "Provides opportunities for {all styles of play}, from the most active to the most passive, catering to both yield-focused investors and hardcore gamers alike.",
                "Accessible to {all budgets}, from free entry with avatars granting access to character minting for quests, to the most limited supply buildings.",
            ],
        },
        {
            title: 'ECONOMIC',
            image: 'images/shipyard_800x600.png',
            reverse: true,
            text: 'Through a carefully designed economic model, the game ensures long-term engagement and viability, benefiting both new and existing players. Implementing a halving mechanism for our sole in-game token ($Barrels), which will be essential for gameplay and most actions. Plus, this $barrels will be NFTs and not ERC20 tokens. Additionally, those aiming for the lucrative legendary level must be willing to take the risk of burning characters and other assets.',
            description: [
                "Implementing a {halving mechanism} for our sole in-game token ($BARRELS), which will be essential for gameplay and most actions. Plus, this {$BARRELS will be NFTs} and not ERC20 tokens.",
                "Those aiming for the {lucrative legendary level} must be willing to take the risk of burning characters and other assets.",
                "Earning money requires risking asset buming and substantial {$BARRELS usage}.",
                "{No need of any pools} as the main token cannot be used as such but must 'mint' some 'bottle of rum'. These bottles won't be tokens but NFTs.",
                "{Eliminating off-chain tokens}, we removed significant risks, such as the frequent occurrence of hacking incidents seen in many play-to-earn games.",
            ],
        },
    ];

    return (

        <NFTSection title={'Game Overview'} bgOpacity='0.7'>
            <VStack flexDirection={'column'} maxW="1200px" gap={10}>
                <HighlightedText fontSize={{ base: "md", md: "xl" }} mx={10}>
                    {"{For Loot And Glory} is a {pirate-themed strategic RPG} that immerses players in the {thrilling world of high-seas adventure}.\nSet in a richly detailed universe brimming with {danger} and {opportunity}, players will embark on {epic quests}, engage in {strategic naval battles}, and build their own {pirate empire}."}
                </HighlightedText>
                <Flex justifyContent={'space-between'} mx={[5, 10]} my={5} >
                    <Image src={'images/pirate.png'} width={['100px', '300px']} />
                    <Image src={'images/corsair.png'} width={['100px', '300px']} />
                    <Image src={'images/smuggler.png'} width={['100px', '300px']} />
                </Flex>
                <SimpleGrid columns={{ base: 1, md: 3 }} spacing={6}>
                    {cards.map((card, index) => (
                        <Box
                            key={index}
                            borderRadius="md"
                            shadow="md"
                            p={6}
                            bg="rgba(0, 0, 0, 0.7)"
                            _hover={{ shadow: "lg", transform: "translateY(-5px)", transition: "0.3s ease" }}
                        >
                            <Flex justifyContent={'center'} mb={4} gap={5} pr={'25px'}>
                                <Image src={card.icon} boxSize={'55px'} mt={'0px'} />
                                <Text fontWeight={'bold'} whiteSpace={'pre-line'}>{card.title}</Text>
                            </Flex>
                            <List spacing={2} textAlign="left">
                                {card.description.map((line, idx) => (
                                    <ListItem key={idx} display="flex" alignItems="flex-start">
                                        <Image src={card.bullet} boxSize={5} mt={'2px'} mr={2} />
                                        <Text textAlign={'justify'} fontSize={13}>{line}</Text>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    ))}
                </SimpleGrid>
                <Box
                    borderRadius="md"
                    p={6}
                    bg="rgba(0, 0, 0, 0.7)"
                >
                    <Flex justifyContent={'center'} alignItems={'center'} mb={10} gap={5} pr={'30px'}>
                        <Text fontSize={20} fontWeight={'bold'} whiteSpace={'pre-line'} textTransform={'uppercase'}>Gameplay Innovations</Text>
                    </Flex>
                    {innovations.map((section, sectionIndex) => (
                        <Flex
                            key={sectionIndex}
                            direction={{ base: "column", md: section.reverse ? "row-reverse" : "row" }}
                            align="center"
                            mb={12}
                            px={5}
                        >
                            <Image
                                src={section.image}
                                alt={section.title}
                                width={{ base: "250px", md: "350px" }}
                            />
                            <Box
                                ml={{ base: 0, md: section.reverse ? 0 : 20 }}
                                mr={{ base: 0, md: section.reverse ? 20 : 0 }}
                                mt={{ base: 6, md: 0 }}
                            >
                                <List spacing={2} textAlign="left">
                                    {section.description.map((line, lineIndex) => {
                                        // Maintain a global index for continuous numbering
                                        const globalIndex =
                                            innovations
                                                .slice(0, sectionIndex)
                                                .reduce((sum, sec) => sum + sec.description.length, 0) + // Sum descriptions of previous sections
                                            (lineIndex + 1); // Add current line index

                                        return (
                                            <ListItem key={lineIndex} display="flex" alignItems="flex-start">
                                                <Text
                                                    fontSize={13}
                                                    fontWeight="bold"
                                                    color="yellow.400"
                                                    mr={2}
                                                    display="inline"
                                                >
                                                    {globalIndex}.
                                                </Text>
                                                <HighlightedText textAlign="justify" fontSize={13}>
                                                    {line}
                                                </HighlightedText>
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            </Box>
                        </Flex>
                    ))}
                </Box>

                <Flex justifyContent={'space-between'} mx={[5, 10]} my={5}  >
                    <Image src={'images/caravel_travel.png'} width={['100px', '300px']} />
                    <Image src={'images/schooner_travel.png'} width={['100px', '300px']} />
                    <Image src={'images/squarerig_travel.png'} width={['100px', '300px']} />
                </Flex>

                <Flex flexDirection={['column', 'row']} gap={3} >
                    <Link
                        href="https://docs.forlootandglory.io"
                        target="_blank"
                        rel="noopener noreferrer"
                        _hover={{ textDecoration: 'none' }}
                    >
                        <Button
                            colorScheme="teal"
                            width="220px"
                            leftIcon={<FaBookOpen size={20} />}
                        >
                            <Text fontSize={16}>Documentation</Text>
                        </Button>
                    </Link>
                    <Link
                        href="https://game.forlootandglory.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        _hover={{ textDecoration: 'none' }}
                    >
                        <Button
                            colorScheme="teal"
                            width="220px"
                            leftIcon={<GiSwordClash size={20} />}
                        >
                            <Text fontSize={16}>Play Game</Text>
                        </Button>
                    </Link>
                    <Link
                        href="https://testnet.forlootandglory.io/"
                        target="_blank"
                        rel="noopener noreferrer"
                        _hover={{ textDecoration: 'none' }}
                    >
                        <Button
                            colorScheme="teal"
                            width="220px"
                            leftIcon={<GiSwordClash size={20} />}
                        >
                            <Text fontSize={16}>Play Game (TestNet)</Text>
                        </Button>
                    </Link>
                </Flex>
                {/* <VStack spacing={6}>
                        <Text>
                            Our pirate-themed game is a RPG / strategy game. You will be able to hunt for coins, level-up your characters, craft gears, and many other things described below. The layer used is Skale, and all transactions gas fees are **free**. The first chapter is **PVE**.
                        </Text>
                        <Icon as={GiPirateHook} boxSize={8} color="teal" />
                        <Text>
                            It will first be deployed on testnet to allow people to actually play the game before investing any cent, as we believe it is how it should always be—delivering the game **first**, instead of selling something without delivering it.
                        </Text>
                        <Icon as={GiPirateCaptain} boxSize={8} color="teal" />
                        <Text>
                            During this phase, you will be rewarded with the **free-to-mint** (with conditions) NFT called the **FLAG PIRATE NFT**. With it, you’ll be able to start your pirate’s journey.
                        </Text>
                        <Icon as={GiPirateFlag} boxSize={8} color="teal" />
                        <Text>
                            Join our Discord, log in to our Dapps, and claim your role based on the games you are active in. This will provide access to events and many rewards.
                        </Text>
                    </VStack> */}
            </VStack>
        </NFTSection >
    );
};

export default Game;
