import { Heading } from '@chakra-ui/react';

const Title = ({ children, ...props }) => {
    return (
        <Heading
            as="h2"
            fontSize={{ base: 'xl', md: '2xl', lg: '4xl' }}
            mb={4}
            borderBottom="1px"
            borderBottomColor={'yellow.400'}
            borderBottomStyle="outset"
            {...props}
        >
            {children}
        </Heading>
    );
};

export default Title;