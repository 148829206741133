import React from 'react';
import { Text } from '@chakra-ui/react';

const HighlightedText = ({ children, ...props }) => {
	// Split children text by spaces while keeping highlightable segments intact
	const parts = typeof children === 'string' ? children.split(/(\{.*?\})/) : [];

	return (
		<Text {...props}>
			{parts.map((part, index) =>
				part.startsWith("{") && part.endsWith("}") ? (
					<Text
						as="span"
						color="yellow.400"
						fontWeight="bold"
						key={index}
					>
						{part.slice(1, -1)}
					</Text>
				) : (
					<Text as="span" key={index} whiteSpace={'pre-line'}>
						{part}
					</Text>
				)
			)}
		</Text>
	);
};

export default HighlightedText;
