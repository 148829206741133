import React, { useRef, useEffect } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import NFTSection from '../components/Section';

const NFTCarousel = () => {
    const carouselRef = useRef(null);
    const isPaused = useRef(false); // Ref to track the paused state
    const nftImages = Array.from({ length: 15 }, (_, i) => `images/nft/${i + 1}.jpg`);

    useEffect(() => {
        const carousel = carouselRef.current;
        let startPosition = 0;
        const speed = 1; // Pixels per frame

        const moveCarousel = () => {
            if (!isPaused.current) {
                startPosition -= speed;
                if (Math.abs(startPosition) >= carousel.scrollWidth / 2) {
                    startPosition = 0; // Reset to create a seamless loop
                }
                carousel.style.transform = `translateX(${startPosition}px)`;
            }
            requestAnimationFrame(moveCarousel);
        };

        moveCarousel(); // Start animation
        return () => cancelAnimationFrame(moveCarousel); // Cleanup
    }, []);

    return (
        <NFTSection title={'NFT Collection (Free Mint)'}>
            <Box
                overflow="hidden"
                position="relative"
                onMouseEnter={() => { isPaused.current = true; }} // Pause animation
                onMouseLeave={() => { isPaused.current = false; }} // Resume animation
            >
                <Flex ref={carouselRef} display="inline-flex" whiteSpace="nowrap">
                    {nftImages.concat(nftImages).map((src, index) => (
                        <Image
                            key={index}
                            src={src}
                            alt={`NFT ${index + 1}`}
                            boxSize={{ base: '150px', md: '200px', lg: '250px' }} // Responsive size
                            borderRadius="md"
                            mx={{ base: 1, md: 2 }} // Responsive margin
                        />
                    ))}
                </Flex>
            </Box>
        </NFTSection>
    );
};

export default NFTCarousel;