import { Box, Text } from "@chakra-ui/react"

const Footer = () => {
    return (
        <Box
            textAlign="center"
            py={4}
            bg="rgba(0, 0, 0, 0.9)"
            borderTop="1px solid rgba(255, 255, 255, 0.2)"
        >
            <Text>&copy; {new Date().getFullYear()} Powered by FLAG.</Text>
        </Box>
    )
}

export default Footer;
